// src/api/ApiClient.js
import axios from 'axios';
import log from '../util/logger';

class ApiClient {
  constructor(baseURL = 'http://localhost/') {
    log.info('[********* APICLIENT INSTANCED *********] WITH BASEURL:', baseURL);
    this.access_token = null;
    this.user = null;
    this.listeners = [];

    this.axiosConfig = {
      baseURL: baseURL || process.env.REACT_APP_API_URL || 'http://localhost:3000',
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'user_agent': 'OpenAPI-Generator/0.1.0/Javascript'

      },
    };

    this.callApi = axios.create(this.axiosConfig);
    this.callApi.interceptors.request.use(config => {
      log.info('### interceptors.request:', config)
      config.headers['Authorization'] = this.getAccessToken() ? `Bearer ${this.getAccessToken()}` : undefined;
      return config;
    });


  }

  // Method to handle GET requests
  async get(endpoint, params = {}) {
    try {
      const response = await this.callApi.get(endpoint, {params});
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }


  async post(path, httpMethod, pathParams,
             queryParams, headerParams, formParams, bodyParam, authNames, contentTypes, accepts,
             returnType, apiBasePath) {
    log.info('[POST] request to endpoint:', path + ' with data:', formParams, `and content type: ${contentTypes[0] || 'application/json'}`);
    try {
      const response = await this.callApi.post(path, formParams,
        {
          headers:
            {'Content-Type': contentTypes[0] || 'application/json'}
        });
      return response.data;
    } catch (error) {
      log.error('[POST] error:', error);
      this.handleError(error);
    }
  }

  // Method to handle PUT requests
  async put(endpoint, data) {
    try {
      const response = await this.callApi.put(endpoint, data);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Method to handle DELETE requests
  async delete(endpoint) {
    try {
      const response = await this.callApi.delete(endpoint);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Optional: Method for handling file uploads
  async uploadFile(endpoint, file) {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await this.callApi.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Error handler
  handleError(error) {
    // Log the error (or send it to a logging service)
    log.error('API call error:', error);

    if (error.response) {
      // Request was made but server responded with a status code not in 2xx
      log.error('Error response:', error.response.data);
      log.error('Status:', error.response.status);
    } else if (error.request) {
      // Request was made but no response received
      log.error('No response received:', error.request);
    } else if (error.detail) {
      // Request was made but no response received
      log.error('response.detail:', error.detail);
    } else {
      // Something else went wrong while setting up the request
      log.error('Error message:', error.message);
    }

    // Throw the error so it can be caught by the calling code if needed
    if (error.response && error.response.data && error.response.data.detail) {
      throw new Error(error.response.data.detail);
    } else if (error.request) {
      throw new Error(`Connection issue, please check your internet connection. Details: ${error.message}`);
    } else {
      throw new Error('API Error: ' + error)
    }
  }

  signup(email, password) {
    log.info('[signup] received email:', email);
    // verify the required parameter 'userRegister' is set
    if (email === undefined || email === null) {
      throw new Error("Missing the required parameter 'userRegister' when calling registerUser");
    }
    const postBody = {'email': email, 'password': password};

    const config = {
      method: 'post',
      url: 'users/signup',
      data: postBody,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.callApi(config)
      .then(response => response.data)
      .catch(error => {
        log.error('[signup] error:', error);
        this.handleError(error);
      });
  }

  signin(email, password) {
    log.info('[signin] received email:', email);
    return this.loginAccessTokenWithHttpInfo(email, password);
  }

  loginAccessTokenWithHttpInfo(username, password, opts = {}) {
    log.info('[loginAccessTokenWithHttpInfo] received username:', username, ' and opts:', opts);
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'username' is set
    if (username === undefined || username === null) {
      throw new Error("Missing the required parameter 'username' when calling loginAccessToken");
    }
    // verify the required parameter 'password' is set
    if (password === undefined || password === null) {
      throw new Error("Missing the required parameter 'password' when calling loginAccessToken");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'grant_type': opts['grantType'],
      'username': username,
      'password': password,
      'scope': opts['scope'],
      'client_id': opts['clientId'],
      'client_secret': opts['clientSecret']
    };
    let authNames = [];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    // let returnType = _Token.default;
    return this.post('/login/access-token', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, null);
  }

  signinWithProvider(name) {
    throw new Error('Method not implemented.');
  }

  signout() {
    log.info('[signout] called.');
    this.setAccessToken(null);
    // Remove user from localStorage
    localStorage.removeItem('user');
    return Promise.resolve(null);
  }

  async recoverPasswordWithHttpInfo(email) {
    if (email === undefined || email === null) {
      throw new Error("Missing the required parameter 'email' when calling recoverPassword");
    }

    const config = {
      url: `password-recovery/${email}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await this.callApi(config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  sendPasswordResetEmail(email) {
    log.info('[sendPasswordResetEmail] called with email:', email);
    return this.recoverPasswordWithHttpInfo(email);
  }

  confirmPasswordReset(token_to_reset_password, newPassword) {
    return this.resetPasswordWithHttpInfo(token_to_reset_password, newPassword);
  }

  async resetPasswordWithHttpInfo(token_to_reset_password, newPassword) {
    if (newPassword === undefined || newPassword === null) {
      log.erro("Missing the required parameter 'newPassword' when calling resetPassword");
      throw new Error("Missing the required parameter 'newPassword' when calling resetPassword");
    }

    if (token_to_reset_password === undefined || token_to_reset_password === null) {
      log.error("Missing the required parameter 'token_to_reset_password' when calling resetPassword");
      throw new Error("Missing the required parameter 'token_to_reset_password' when calling resetPassword");
    }

    const jsonPassword = {
      "token": token_to_reset_password,
      "new_password": newPassword
    }

    const config = {
      url: 'reset-password/',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: jsonPassword,
    }

    log.info("resetPasswordWithHttpInfo config:", config);

    try {
      const response = await this.callApi(config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  updatePasswordMeWithHttpInfo(user_acess_token, currentPass, newPassword) {
    log.info('[updatePasswordMeWithHttpInfo] called with newPassword and access_token:', user_acess_token);
    let postBody = {
      "current_password": currentPass,
      "new_password": newPassword
    }
    // verify the required parameter 'updatePassword' is set
    if (currentPass === undefined || currentPass === null) {
      throw new Error("Missing the required parameter 'updatePassword' when calling updatePasswordMe");
    }
    if (newPassword === undefined || newPassword === null) {
      throw new Error("Missing the required parameter 'updatePassword' when calling updatePasswordMe");
    }

    const config = {
      url: 'users/me/password',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': user_acess_token,
      },
      data: postBody,
    };

    return this.callApi(config)
      .then(response => response.data)
      .catch(error => {
        log.error('[updatePasswordMeWithHttpInfo] error:', error);
        this.handleError(error);
      });
  }

  updatePassword(user_acess_token, currentPass, newPassword) {
    log.info('[updatePassword] called with newPassword and access_token:', this.access_token);
    return this.updatePasswordMeWithHttpInfo(user_acess_token, currentPass, newPassword);
  }

  updateEmail(email) {
    log.info('[updateEmail] called with email:', email + ' and access_token:', this.access_token);
    throw new Error('Method not implemented.');
  }

  updateUserMeWithHttpInfo(userId, userUpdateMe) {
    log.info('[updateUserWithHttpInfo] called with data:', userUpdateMe, ' and userId:', userId);
    let postBody = userUpdateMe;
    // verify the required parameter 'userUpdateMe' is set
    if (userUpdateMe === undefined || userUpdateMe === null) {
      throw new Error("Missing the required parameter 'userUpdateMe' when calling updateUserMe");
    }

    const config = {
      url: 'users/me',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      data: postBody,
    };

    return this.callApi(config)
      .then(response => response.data)
      .catch(error => {
        log.error('[updateUserMeWithHttpInfo] error:', error);
        this.handleError(error);
      });
  }

  updateUserWithHttpInfo(userId, data) {
    log.info('[updateUserWithHttpInfo] called with data:', data, ' and userId:', userId);
    let postBody = data;

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling updateUser");
    }
    // verify the required parameter 'data' is set
    if (data === undefined || data === null) {
      throw new Error("Missing the required parameter 'data' when calling updateUser");
    }

    const config = {
      url: `/api/v1/users/me`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      data: postBody,
    };

    return this.callApi(config)
      .then(response => response.data)
      .catch(error => {
        log.error('[updateUserWithHttpInfo] error:', error);
        this.handleError(error);
      });
  }

  checkAPIStatus() {
    log.info("[checkAPIStatus] called.");

    const config = {
      url: `/utils/checkstatus`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.callApi(config)
      .then(response => response.data)
      .catch(error => {
        log.error('[updateUserWithHttpInfo] error:', error);
        this.handleError(error);
      });
  }


  updateProfile(userid, data) {
    log.info('[updateProfile] called with data:', data, ' and userId:', userid)
    // desestructure data, if key name is present, change it to full_name to match backend
    const {name, ...rest} = data;
    const userUpdateMe = {...rest, full_name: name};
    return this.updateUserMeWithHttpInfo(userid, userUpdateMe)
  }

  async getCurrentUserFromLocalStorage() {
    log.info('[getCurrentUserFromLocalStorage] called.');
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      log.info('[getCurrentUserFromLocalStorage] Found user in localStorage:', storedUser);
      this.setUser(JSON.parse(storedUser));
      return JSON.parse(storedUser);
    } else {
      log.info('[getCurrentUserFromLocalStorage] No user found in localStorage, trying get from API.');
      return this.getCurrentUser()
    }
  }


  async getCurrentUser() {
    log.info('[getCurrentUser] called.');
    log.info('[getCurrentUser] access_token:', this.access_token);
    if (!this.access_token) {
      log.error('[getCurrentUser] access_token not found.');
      return null;
    }

    const config = {
      ...this.axiosConfig,
      method: 'get',
      url: '/users/me',
      headers: {
        // eslint-disable-next-line no-useless-concat
        'Authorization': 'Bearer' + ' ' + this.access_token, // Assuming accessToken is stored in apiClient
        'Accept': 'application/json'
      }
    };

    log.info('getCurrentUser axios config:', config)

    try {
      const response = await axios(config);
      log.info('### getCurrentUser response:', {
        ...response.data,
        'uid': response.data.id,
        'name': response.data.full_name,
        access_token: this.access_token
      });
      // Add uid and name to response as alias to be compatible with frontend
      return {'uid': response.data.id, 'name': response.data.full_name, ...response.data};
    } catch (error) {
      throw error;
    }
  }


  getAccessToken() {
    log.info('[getAccessToken] called., returning access_token:', this.access_token);
    return this.access_token;
  }

  setAccessToken(access_token) {
    log.info('[setAccessToken] called with access_token:', access_token)
    // set access token if it now null or undefined
    if (access_token) {
      this.access_token = access_token;
      log.info('[setAccessToken] access_token set to:', access_token);
    } else {
      log.info('[setAccessToken] access_token received is null or undefined, IGNORING IT.');
    }
  }

  setUser(response) {
    log.info('[setUser] called with response:', response);
    this.user = response;
    if (response.access_token) {
      this.setAccessToken(response.access_token);
    }
    // Store this.user in localStorage
    localStorage.setItem('user', JSON.stringify(response));
    this.notifyListeners();
  }

  notifyListeners() {
    this.listeners.forEach(listener => listener({user: this.user}));
  }

  onChange(callback) {
    this.listeners.push(callback);
    return () => {
      this.listeners = this.listeners.filter(listener => listener !== callback);
    };
  }

  /**
   * Create New Company
   * Create a new company.
   * @param {module:model/CompanyCreate} companyCreate
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyRead} and HTTP response
   */
  createNewCompanyWithHttpInfo(companyCreate) {
    if (companyCreate === undefined || companyCreate === null) {
      log.error("Missing the required parameter 'companyCreate' when calling createNewCompany");
      throw new Error("Missing the required parameter 'companyCreate' when calling createNewCompany");
    }

    const config = {
      url: 'company/',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: companyCreate,
    };

    return this.callApi(config)
      .then(response => response.data)
      .catch(error => {
        log.error('error: ', error);
        this.handleError(error);
      });
  }

  /**
   * Create New Company
   * Create a new company.
   * @param {module:model/CompanyCreate} companyCreate
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyRead}
   */
  createNewCompany = async (companyCreate) => {
    try {
      const response_and_data = await this.createNewCompanyWithHttpInfo(companyCreate);
      log.info('[createNewCompany] response: ', response_and_data);
      const user = await this.getCurrentUserFromLocalStorage();
      this.setUser({...user, 'isnew': false});
      return response_and_data;
    } catch (error) {
      log.error('[createNewCompany] error: ', error);
      this.handleError(error);
    }
  }

  static convertToType(data, type) {
    if (data === null || data === undefined)
      return data

    switch (type) {
      case 'Boolean':
        return Boolean(data);
      case 'Integer':
        return parseInt(data, 10);
      case 'Number':
        return parseFloat(data);
      case 'String':
        return String(data);
      case 'Date':
        return ApiClient.parseDate(String(data));
      case 'Blob':
        return data;
      default:
        if (type === Object) {
          // generic object, return directly
          return data;
        } else if (typeof type.constructFromObject === 'function') {
          // for model type like User and enum class
          return type.constructFromObject(data);
        } else if (Array.isArray(type)) {
          // for array type like: ['String']
          var itemType = type[0];

          return data.map((item) => {
            return ApiClient.convertToType(item, itemType);
          });
        } else if (typeof type === 'object') {
          // for plain object type like: {'String': 'Integer'}
          var keyType, valueType;
          for (var k in type) {
            if (type.hasOwnProperty(k)) {
              keyType = k;
              valueType = type[k];
              break;
            }
          }

          var result = {};
          for (var k in data) {
            if (data.hasOwnProperty(k)) {
              var key = ApiClient.convertToType(k, keyType);
              var value = ApiClient.convertToType(data[k], valueType);
              result[key] = value;
            }
          }

          return result;
        } else {
          // for unknown type, return the data directly
          return data;
        }
    }
  }

}

export default ApiClient;
