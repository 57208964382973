import React, {Suspense, useState} from 'react';
import {useTranslation} from "react-i18next";

function Tools() {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
// Lazy load AIToolsCard component
  const AIToolsCard = React.lazy(() => import("../../partials/ecommerce/AIToolsCard"));

  const tools_list = [
    {
      "key": "brand_slogan_generator",
      "image": "AppImage08",
      "popular": "",
      "stars": "4.2",
      "stars_qtd": "(22)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/slogan-generator"
    },
    {
      "key": "product_name_generator",
      "image": "AppImage04",
      "popular": "",
      "stars": "4.2",
      "stars_qtd": "(78)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/product-name-generator"
    },
    {
      "key": "5_why_analysis",
      "image": "AppImage16",
      "popular": "",
      "stars": "4.8",
      "stars_qtd": "(12)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/5-why-analysis"
    },
    {
      "key": "contract_generator",
      "image": "AppImage14",
      "popular": "",
      "stars": "4.2",
      "stars_qtd": "(22)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/contract-generator"
    },
    {
      "key": "ai_army",
      "image": "AppImage12",
      "popular": "",
      "stars": "4.2",
      "stars_qtd": "(22)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://page.mindpal.space/66b84b979970500d7118d392"
    },
    {
      "key": "high_search_intent_blog_post_idea",
      "image": "AppImage13",
      "popular": "Popular",
      "stars": "4.8",
      "stars_qtd": "(67)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/blog-post-ideas-from-url"
    },
    {
      "key": "website_porters_5_forces_analysis",
      "image": "AppImage20",
      "popular": "Popular",
      "stars": "4.8",
      "stars_qtd": "(67)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/website-analysis-5-forces"
    },
    {
      "key": "website_feedback",
      "image": "AppImage23",
      "popular": "Popular",
      "stars": "4.8",
      "stars_qtd": "(67)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/landpage-feedback-generator"
    },
    {
      "key": "facebook_post",
      "image": "AppImage19",
      "popular": "",
      "stars": "4.5",
      "stars_qtd": "(30)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/fb-post-from-keyword"
    },
    {
      "key": "twitter_post",
      "image": "AppImage12",
      "popular": "",
      "stars": "4.6",
      "stars_qtd": "(28)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/twitter-post-from-keyword"
    },
    {
      "key": "instagram_post",
      "image": "AppImage05",
      "popular": "Popular",
      "stars": "4.8",
      "stars_qtd": "(45)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/insta-post-from-keyword"
    },
    {
      "key": "blog_post",
      "image": "AppImage21",
      "popular": "Editor's Pick",
      "stars": "4.7",
      "stars_qtd": "(52)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/blog-post-from-keyword"
    },
    {
      "key": "customer_birthday_message",
      "image": "AppImage03",
      "popular": "",
      "stars": "4.9",
      "stars_qtd": "(60)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/birthday-message"
    },
    {
      "key": "linkedin_post",
      "image": "AppImage29",
      "popular": "",
      "stars": "4.6",
      "stars_qtd": "(35)",
      "price": "Free",
      "price_normal": "$59.00",
      "url": "https://chat.sunokrom.com/linkedin-post-from-keyword"
    }
  ];


  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex overflow-hidden">
      <div className="relative flex flex-col flex-1 overflow-x-hidden">
        <main className="grow">
          <div className="px-0 sm:px-0 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="mb-2">
              {/* Title */}
              <h1 className="md:text-3xl text-gray-800 dark:text-gray-100 font-bold">
                {t('tools.title.title')}
              </h1>
            </div>
            {/* Page content */}
            <div>
              <div className="mt-8">
                <div className="grid grid-cols-12 gap-6">
                  <Suspense fallback={
                    <div className="flex justify-center items-center w-full h-full">
                      {/* Shadow and loading animation */}
                      <div className="w-16 h-16 rounded-full shadow-lg animate-pulse bg-gray-300"></div>
                    </div>
                  }>
                    {tools_list.map((tool, index) => (
                      <AIToolsCard
                        key={index}
                        image={tool.image}
                        popular={tool.popular}
                        title={t(`tools.${tool.key}.title`)}
                        description={t(`tools.${tool.key}.description`)}
                        stars={tool.stars}
                        stars_qtd={tool.stars_qtd}
                        price={tool.price}
                        price_normal={tool.price_normal}
                        url={tool.url}
                      />
                    ))}
                  </Suspense>


                </div>
              </div>
            </div>
          </div>
        </main>

      </div>

    </div>
  );
}

export default Tools;