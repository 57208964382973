import React from 'react';
// import Header from '../partials/Header';
import AnalyticsCard01 from '../partials/analytics/AnalyticsCard01';
import AnalyticsCard02 from '../partials/analytics/AnalyticsCard02';
import AnalyticsCard03 from '../partials/analytics/AnalyticsCard03';
import AnalyticsCard04 from "../partials/analytics/AnalyticsCard04";
import SectionHeader from "../components/SectionHeader";
import Section from "../components/Section";

function Analytics(props) {

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-12 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 xl">

          {/* Line chart (Analytics) */}
          <AnalyticsCard01/>
          {/*  Line chart (Active Users Right Now) */}
          <AnalyticsCard02/>
          {/* Stacked bar chart (Acquisition Channels) */}
          <AnalyticsCard03/>
          {/* Horizontal bar chart (Audience Overview) */}
          <AnalyticsCard04/>

          {/*/!* Report card (Top Channels) *!/*/}
          {/*<AnalyticsCard05 />*/}
          {/*/!* Report card (Top Pages) *!/*/}
          {/*<AnalyticsCard06 />*/}
          {/*/!* Report card (Top Countries) *!/*/}
          {/*<AnalyticsCard07 />*/}
          {/*/!* Doughnut chart (Sessions By Device) *!/*/}
          {/*<AnalyticsCard08 />*/}
          {/*/!* Doughnut chart (Visit By Age Category) *!/*/}
          {/*<AnalyticsCard09 />*/}
          {/*/!* Polar chart (Sessions By Gender) *!/*/}
          {/*<AnalyticsCard10 />*/}
          {/*/!* Table (Top Products) *!/*/}
          {/*<AnalyticsCard11 />*/}
        </div>
      </div>
    </Section>
  );


}

export default Analytics;