import React, {createContext, useContext, useEffect, useState,} from "react";
import queryString from "query-string";
// import fakeAuth from "fake-auth";
import {history} from "./router";
import PageLoader from "./../components/PageLoader";

import analytics from "./analytics";
// import ApiClient from "../api/ApiClient";
import api from "../api";

import log from './logger'

// Whether to merge extra user data from database into `auth.user`
// const MERGE_DB_USER = true;

// Whether to connect analytics session to `user.uid`
const ANALYTICS_IDENTIFY = true;

// const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";
// const api = new ApiClient(API_URL)

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();
export const useAuth = () => useContext(authContext);

// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({children}) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that creates the `auth` object and handles state
// This is called from `AuthProvider` above (extracted out for readability)
function useAuthProvider() {
  // Store auth user in state
  // `user` will be object, `null` (loading) or `false` (logged out)
  const [user, setUser] = useState(null);

  // Merge extra user data from the database
  // This means extra user data (such as payment plan) is available as part
  // of `auth.user` and doesn't need to be fetched separately. Convenient!
  // let finalUser = useMergeExtraData(user, { enabled: MERGE_DB_USER });
  let finalUser = user;

  // Add custom fields and formatting to the `user` object
  // finalUser = useFormatUser(finalUser);

  // Connect analytics session to user - TEMPORARY DISABLED
  useIdentifyUser(finalUser, {enabled: ANALYTICS_IDENTIFY});

  // Handle response from auth functions (`signup`, `signin`, and `signinWithProvider`)
  const handleAuth = async (user) => {
    log.info('[handleAuth] RECEIVED:', user)

    let response = '';

    // if user has access_token property, then user add it in api.access_token
    if (user.access_token) {
      api.setAccessToken(user.access_token);
      let currentUser = await api.getCurrentUser();
      response = {'isnew': false, ...currentUser, ...user};

      api.setUser(response);
      setUser(response);
      log.info('[handleAuth] currentUser:', currentUser + ' access_token on class' + api.access_token)
    }

    // Update user in state
    // setUser(user);
    return response;
  };

  const CheckStatus = async () => {
    // loop for checking status of api 3 times
    for (let i = 0; i < 3; i++) {
      const result = await api.checkAPIStatus();
      log.info('[CheckStatus] :', result)
      if (result && i > 2) {
        return result;
      }
    }
  }

  const signup = (email, password) => {
    return api
      .signup(email, password)
      .then((response) => {
        if (response.access_token) {
          response.isnew = true;
        }
        log.info('[signup] response:', response)
        handleAuth(response)
      });
  };

  const signin = (email, password) => {
    return api
      .signin(email, password)
      .then((response) => handleAuth(response));
  };

  const signinWithProvider = (name) => {
    return api
      .signinWithProvider(name)
      .then((response) => handleAuth(response));
  };

  const signout = () => {
    api.signout();
    setUser(false);
  };

  const sendPasswordResetEmail = (email) => {
    log.info('[sendPasswordResetEmail] email:', email)
    return api.sendPasswordResetEmail(email);
  };

  const confirmPasswordReset = (token_to_reset_password, newPassword) => {
    // [INTEGRATING AN AUTH SERVICE]: If not passing in "code" as the second
    // arg above then make sure getFromQueryString() below has the correct
    // url parameter name (it might not be "code").

    // Get code from query string object
    // const resetCode = code || getFromQueryString("code");
    return api.confirmPasswordReset(token_to_reset_password, newPassword);
  };

  const updatePassword = (data) => {
    return api.updatePassword(user.access_token, data.currentPass, data.pass);
  };

  // Update auth user and persist data to database
  // Call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    log.info('[updateProfile] received:', data)
    const {email, name, picture} = data;

    // Update built-in auth profile fields
    // These fields are renamed in `useFormatUser`, so when updating we
    // need to make sure to use their original names (`name`, `picture`, etc)
    if (name || email || picture) {
      let fields = {};
      if (name) fields.name = name;
      if (email) fields.email = email;
      if (picture) fields.picture = picture;

      // Compare fields with auth.user and create a structure with differences
      let differences = {};
      for (let key in fields) {
        if (fields[key] !== user[key]) {
          differences[key] = fields[key];
        }
      }

      if (Object.keys(differences).length > 0) {
        await api.updateProfile(user.uid, differences);
      }
    }

    // Persist all data to the database
    // await updateUser(user.uid, data);

    // Update user in state
    api.getCurrentUser().then((response) => {
      handleAuth(response)
    });

  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     log.info('[handleAuth] user:', user);
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [user]);

  useEffect(() => {
    log.info(CheckStatus());
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      log.info('[useEffect] Found user in localStorage:', storedUser);
      setUser(JSON.parse(storedUser));
      api.setUser(JSON.parse(storedUser));
    } else {
      log.info('[useEffect] No user found in localStorage, redirecting to sign-in page.');
      history.replace("/auth/signin");
    }
    const unsubscribe = api.onChange(async ({user}) => {
      log.info('[useEffect] subscribing to user on mount')
      if (user) {
        log.info('[useEffect] setting user:', user)
        setUser(user);
      } else {
        log.info('[useEffect] setting user:', user)
        setUser(false);
      }

    });
  }, []);

  // useEffect(() => {
  //   // Subscribe to user on mount
  //   log.info('[useEffect auth.js] subscribing to user on mount')
  //   log.info('[useEffect auth.js] user:', user)
  //   const unsubscribe = api.onChange(async ({ user }) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       setUser(false);
  //     }
  //   });
  //
  //   // Unsubscribe on cleanup
  //   return () => unsubscribe();
  // }, []);

  return {
    user: finalUser,
    signup,
    signin,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updatePassword,
    updateProfile,
  };
}


// Connect analytics session to current user
function useIdentifyUser(user, {enabled}) {
  useEffect(() => {
    if (user && user.uid && enabled) {
      log.info('[useIdentifyUser USEEFFECT]  identifying user:', user)
      analytics.identify(user.uid);
    }
  }, [user, enabled]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return function RequireAuthHOC(props) {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        history.replace("/auth/signin");
      }
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is `null`) or about to redirect from above `useEffect` (user is `false`)
    if (!auth.user) {
      log.info('[requireAuth] auth.user not found:', auth);
      return <PageLoader/>;
    }


    // Render component now that we have user
    return <Component {...props} />;
  };
};

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};
