import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useFormContext} from '../../util/FormContext';
import {Link} from "react-router-dom";
import api from "../../api";
import log from '../../util/logger';
import CompanyCreate from "../../api/model/CompanyCreate";


const Step4Form = forwardRef((props, ref) => {
  const {formData, updateFormData} = useFormContext();

  // State for form fields, initialized from formData
  const [formFields, setFormFields] = useState({
    companyName: formData.companyName || '',
    city: formData.city || '',
    postalCode: formData.postalCode || '',
    street: formData.street || '',
    country: formData.country || 'USA',
  });

  // State for form errors
  const [errors, setErrors] = useState({});

  // formFields data structure
//   {
//     "option": "website",
//     "website": "www.sunokrom.com",
//     "companyName": "sunokrom",
//     "city": "miami",
//     "postalCode": "",
//     "street": "547 north st",
//     "country": "USA",
//     "facebook": "facebook",
//     "instagram": "insta",
//     "twitter": "twitter",
//     "linkedin": "linkedin",
//     "googleBusiness": false,
//     "websiteTechnology": "Other"
// }

  // Create company model
//   {
//   "name": "string",
//   "registration_number": "string",
//   "industry": "string",
//   "website_url": "string",
//   "logo_url": "string",
//   "contact_email": "user@example.com",
//   "contact_phone": "string",
//   "address": "string",
//   "city": "string",
//   "state": "string",
//   "postal_code": "string",
//   "country": "string",
//   "description": "string",
//   "subscription_plan": "string"
// }


  const createCompany = (formFields) => {
    let company = new CompanyCreate()
    company.name = formFields.companyName
    company.registration_number = Math.floor(Math.random() * 1000000000).toString()
    company.city = formFields.city
    company.postalCode = formFields.postalCode
    company.street = formFields.street
    company.country = formFields.country
    company.website_url = formFields.website


    api.createNewCompany(company).then((response) => {
      log.info('Step4Form: createCompany response:', response);
      //   check if response have field created_at
      if (response && (response.created_at !== undefined || response.created_at !== null)) {

      }
    });
  }

  // Synchronize formData with state changes
  useEffect(() => {
    updateFormData(formFields);
    log.info('Step4Form: formData:', formData);
    createCompany(formFields)

    window.scrollTo(0, 0);
  }, []);

  // Generic handle change function for all inputs
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));

    updateFormData(formFields);
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    Object.keys(formFields).forEach((field) => {
      if (!formFields[field]) {
        newErrors[field] = true;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  // Expose validation and submission functions to parent component
  useImperativeHandle(ref, () => ({
    validateForm,
    submitForm: () => {
      if (validateForm()) {
        updateFormData(formFields);
      }
    },
  }));

  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <div className="text-center">
          <svg className="inline-flex w-16 h-16 fill-current mb-6" viewBox="0 0 64 64">
            <circle className="text-green-500/20" cx="32" cy="32" r="32"/>
            <path className="text-green-700"
                  d="M37.22 26.375a1 1 0 1 1 1.56 1.25l-8 10a1 1 0 0 1-1.487.082l-4-4a1 1 0 0 1 1.414-1.414l3.21 3.21 7.302-9.128Z"/>
          </svg>
          <h1 className="text-3xl text-gray-800 dark:text-gray-100 font-bold mb-8">Nice to have
            you, {formData.companyName} 🙌</h1>
          <Link
            className="btn bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-green-400 dark:text-gray-800 dark:hover:bg-green-200"
            to="/dashboard">
            Go To Dashboard -&gt;
          </Link>
        </div>
      </div>
      {/*<PartyEffect/>*/}

    </div>
  );
});

export default Step4Form;
