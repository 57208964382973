import {useEffect, useRef} from "react";
// import fakeAuth from "fake-auth";
import ApiClient from "../api/ApiClient";
import log from "./logger";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";
const api = new ApiClient(API_URL)

// Clean up markdown string and convert to JSON format
export function markdownStip(markdownString) {
  // Step 1: Strip Markdown tags
  const strippedString = markdownString
    .replace(/(\*\*|__)(.*?)\1/g, '$2') // Bold
    .replace(/(\*|_)(.*?)\1/g, '$2') // Italic
    .replace(/~~(.*?)~~/g, '$1') // Strikethrough
    .replace(/`([^`]+)`/g, '$1') // Inline code
    .replace(/!\[.*?\]\(.*?\)/g, '') // Images
    .replace(/\[(.*?)\]\(.*?\)/g, '$1') // Links
    .replace(/^\s*#\s+/gm, '') // Headers
    .replace(/^\s*##\s+/gm, '') // Sub-headers
    .replace(/^\s*###\s+/gm, '') // Sub-sub-headers
    .replace(/^\s*####\s+/gm, '') // Sub-sub-sub-headers
    .replace(/^\s*#####\s+/gm, '') // Sub-sub-sub-sub-headers
    .replace(/^\s*######\s+/gm, '') // Sub-sub-sub-sub-sub-headers
    .replace(/^\s*>\s+/gm, '') // Blockquotes
    .replace(/^\s*-\s+/gm, '') // Unordered lists
    .replace(/^\s*\d+\.\s+/gm, '') // Ordered lists
    .replace(/^\s*---\s*$/gm, '') // Horizontal rules
    .replace(/^\s*___\s*$/gm, '') // Horizontal rules
    .replace(/^\s*===\s*$/gm, '') // Horizontal rules
    .replace(/\n{2,}/g, '\n') // Multiple newlines to single newline
    .trim();

  // Step 2: Convert to JSON format
  const jsonString = JSON.stringify({content: strippedString});

  // Convert jsonString to JSON object
  const jsonObject = JSON.parse(jsonString);

  return jsonObject;
}

// Make an API request to `/api/{path}`
export function apiRequest(path, method = "GET", data) {
  log.info("[apiRequest] path:", path, "method:", method, "data:", data);
  const accessToken = api.getAccessToken();

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          api.signout();
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  log.info("[apiRequestExternal] url:", url, "method:", method, "data:", data);
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  log.error("[CustomError] code:", code, "message:", message);
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  log.info("[usePrevious] state:", state);
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}
