import React from "react";
import Meta from "./../components/Meta";
import {requireAuth, useAuth} from "./../util/auth";
import Analytics from "./Analytics";
import {useTranslation} from "react-i18next";

function DashboardPage(props) {

  const auth = useAuth();

  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };

  return (
    <>
      <Meta title="Analytics"/>
      <Analytics
        title={t("Analytics_page_title")}
        subtitle={t("Analytics_page_description")}
        strapline=""
        size="md"
        bgColor=""
      />
    </>
  );
}

export default requireAuth(DashboardPage);
