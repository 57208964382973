import React from "react";

function LegalPrivacy() {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none dark:text-dark dark-text-all ">
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated: September, 13, 2024</strong></p>

      <p>At Sunokrom, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you visit our website or use our services. Please read this policy
        carefully. If you do not agree with the terms of this Privacy Policy, please do not access our site or use our
        services.</p>

      <h2>1. Information We Collect</h2>
      <p>We may collect personal and non-personal information from you in various ways, including when you visit our
        site, register on the site, place an order, subscribe to our newsletter, or use other services and activities
        provided by Sunokrom.</p>
      <ul>
        <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and payment
          information.
        </li>
        <li><strong>Non-Personal Information:</strong> We may collect non-personal identification information such as
          browser type, language preference, and the date and time of your visit to our site.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We may use the information we collect from you for the following purposes:</p>
      <ul>
        <li>To provide, operate, and maintain our website and services.</li>
        <li>To improve customer service and personalize user experience.</li>
        <li>To process transactions and manage your orders.</li>
        <li>To send periodic emails regarding updates, promotions, or other news about our services.</li>
        <li>To analyze data for operational and marketing purposes.</li>
      </ul>

      <h2>3. How We Protect Your Information</h2>
      <p>We adopt appropriate data collection, storage, and processing practices, as well as security measures, to
        protect against unauthorized access, alteration, disclosure, or destruction of your personal information,
        username, password, and transaction details.</p>

      <h2>4. Sharing Your Information</h2>
      <p>We do not sell, trade, or rent your personal information to others. We may share generic aggregated demographic
        information not linked to any personal identification information with our business partners, trusted
        affiliates, and advertisers for marketing purposes.</p>

      <h2>5. Cookies</h2>
      <p>Our site may use “cookies” to enhance user experience. Your web browser places cookies on your hard drive for
        record-keeping purposes and sometimes to track information about you. You can choose to set your web browser to
        refuse cookies or alert you when cookies are being sent. Note that some parts of the site may not function
        properly if you disable cookies.</p>

      <h2>6. Third-Party Services</h2>
      <p>We may use third-party service providers to help us operate our business and the site or administer activities
        on our behalf, such as sending out newsletters or surveys. These third parties will have access to your
        information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any
        other purpose.</p>

      <h2>7. Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal information, as well as the right to withdraw
        your consent to data processing. To exercise these rights, please contact us at <a
          href="mailto:support@sunokrom.com">support@sunokrom.com</a>.</p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>Sunokrom reserves the right to update or change this Privacy Policy at any time. We will notify you of any
        changes by updating the “Last Updated” date at the top of this page. Your continued use of the site after any
        changes signifies your acceptance of the new terms.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with
        Sunokrom, please contact us at <a href="mailto:support@sunokrom.com">support@sunokrom.com</a>.</p>

    </div>
  );
}

export default LegalPrivacy;
