// Onboarding01.js
import React, {useRef, useState} from "react";
import Header from "./Header";
import ProgressBar from "./ProgressBar";
import ImageSection from "./ImageSection";
import Step1Form from "./Step1Form";
import {FormProvider, useFormContext} from "../../util/FormContext";
import Step2Form from "./Step2Form";
import Step3Form from "./Step3form";
import Step4Form from "./Step4form";
import {motion} from 'framer-motion';

import log from "./../../util/logger";
import PartyEffect from "../PartyEffect";

function Onboarding01() {
  const [currentStep, setCurrentStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(0);
  const delta = currentStep - previousStep;
  const step2FormRef = useRef();
  const {formData} = useFormContext();


  const steps = [
    {label: "Step 1", path: "/onboarding-01", component: <Step1Form/>},
    {label: "Step 2", path: "/onboarding-02", component: <Step2Form/>},
    {label: "Step 3", path: "/onboarding-03", component: <Step3Form/>},
    {label: "Step 4", path: "/onboarding-04", component: <Step4Form/>},
  ];

  // Show formData every 2 seconds if it changes just if it change
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log(formData);
  //   }, 2000);
  //   return () => clearInterval(interval);
  // }, [formData]);


  const handleNext = () => {
    log.info("handleNext currentStep ", currentStep, " delta ", delta);
    if (currentStep < steps.length - 1) {
      setPreviousStep(currentStep);
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    log.info("handleBack currentStep ", currentStep, " delta ", delta);
    if (currentStep > 0) {
      setPreviousStep(currentStep);
      setCurrentStep(currentStep - 1);
    }
  };

  const handleStepChange = (index) => {
    setCurrentStep(index);
  };


  return (
    <FormProvider>
      <main className="bg-white dark:bg-gray-900">
        <div className="relative flex">
          <div className="w-full md:w-1/2">
            <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
              <div className="flex-1">
                <Header/>
                <ProgressBar steps={steps} currentStep={currentStep} onStepChange={handleStepChange}/>
              </div>

              <motion.div
                key={currentStep}
                initial={{x: delta >= 0 ? '50%' : '-50%', opacity: 0}}
                animate={{x: 0, opacity: 1}}
                transition={{duration: 0.3, ease: 'easeInOut'}}
              >
                {/* Render the current step's form component */}
                {steps[currentStep].component}
              </motion.div>
              <div className="flex justify-between px-4 py-8">
                <button
                  onClick={handleBack}
                  className="btn-lg bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white"
                  disabled={currentStep === 0}
                >
                  &lt;- Back
                </button>
                <button
                  onClick={handleNext}
                  className="btn-lg bg-gray-900 text-gray-100 hover:bg-gray-800 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-white"
                  disabled={currentStep === steps.length - 1}
                >
                  Next Step -&gt;
                </button>
              </div>
            </div>
          </div>
          <ImageSection/>
        </div>
        {currentStep === 3 && <PartyEffect/>}
      </main>
    </FormProvider>
  );
}

// Reusable radio option component
function RadioOption({
                       label
                     }) {
  return (
    <label className="relative block cursor-pointer">
      <input type="radio" name="radio-buttons" className="peer sr-only" defaultChecked={label === "I have a website"}/>
      <div
        className="flex items-center bg-white text-sm font-medium text-gray-800 dark:text-gray-100 p-4 rounded-lg dark:bg-gray-800 border border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 shadow-sm transition">
        <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
          <path
            className="text-violet-500"
            d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
          />
          <path
            className="text-violet-300"
            d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z"
          />
          <path
            className="text-violet-200"
            d="M13 12.588v11l8.486-4.714A1 1 0 0 0 22 18V7.589l-9 4.999Z"
          />
        </svg>
        <span>{label}</span>
      </div>
      <div
        className="absolute inset-0 border-2 border-transparent peer-checked:border-violet-400 dark:peer-checked:border-violet-500 rounded-lg pointer-events-none"
        aria-hidden="true"
      ></div>
    </label>
  );
}

export default Onboarding01;
