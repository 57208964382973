import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import {requireAuth, useAuth} from "./../util/auth";
import {useTranslation} from "react-i18next";

function DashboardPage(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };


  const auth = useAuth();


  return (
    <>
      <Meta title="Dashboard"/>
      <DashboardSection
        title={t("Dashboard_page_title")}
        subtitle="" //{t("Dashboard_page_description")}
        strapline=""
        size="md"
        bgColor=""
      />
    </>
  );
}

export default requireAuth(DashboardPage);
