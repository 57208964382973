import React, {createContext, useContext, useState} from 'react';
import log from './logger';

const FormContext = createContext();

export function useFormContext() {
  return useContext(FormContext);
}

export function FormProvider({children}) {
  const [formData, setFormData] = useState({});

  const updateFormData = (newData) => {
    setFormData((prevData) => ({...prevData, ...newData}));
    log.info('Form data updated:', formData);
  };

  return (
    <FormContext.Provider value={{formData, updateFormData}}>
      {children}
    </FormContext.Provider>
  );
}
