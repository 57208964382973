import React from 'react';
import CampaignsCard from "../../partials/campaigns/CampaignsCard";


import Image02 from '../../images/user-28-02.jpg';
import Image03 from '../../images/user-28-03.jpg';
import Image01 from '../../images/user-28-01.jpg';
import Image04 from '../../images/user-28-04.jpg';
import Image05 from '../../images/user-28-05.jpg';
import Image06 from '../../images/user-28-06.jpg';
import Image07 from '../../images/user-28-07.jpg';
import Image08 from '../../images/user-28-08.jpg';
import Image09 from '../../images/user-28-09.jpg';
import Image10 from '../../images/user-28-10.jpg';
import Image11 from '../../images/user-28-11.jpg';
import Image12 from '../../images/user-28-12.jpg';
import {useTranslation} from "react-i18next";


const simple_statistics_data = {
  instagram: {
    title: "Instagram",
    value: 87,
  },
  facebook: {
    title: "Facebook",
    value: 23,
  },
  twitter: {
    title: "Tweets",
    value: 34,
  },
  linkedin: {
    title: "LinkedIn",
    value: 12,
  },
  youtube: {
    title: "YouTube videos",
    value: 5,
  },
  tiktok: {
    title: "TikTok videos",
    value: 9,
  }
};

const items = [
  {
    id: 0,
    category: '1',
    members: [

      {
        name: 'User 02',
        image: Image02,
        link: '#0'
      },
      {
        name: 'User 03',
        image: Image03,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'One-Time'
  },
  {
    id: 1,
    category: '2',
    members: [
      {
        name: 'User 04',
        image: Image04,
        link: '#0'
      },
      {
        name: 'User 05',
        image: Image05,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'Off-Track'
  },
  {
    id: 3,
    category: '3',
    members: [
      {
        name: 'User 07',
        image: Image07,
        link: '#0'
      },
      {
        name: 'User 08',
        image: Image08,
        link: '#0'
      },
      {
        name: 'User 09',
        image: Image09,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'At Risk'
  },
  {
    id: 4,
    category: '1',
    members: [
      {
        name: 'User 10',
        image: Image10,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'At Risk'
  },
  {
    id: 5,
    category: '4',
    members: [
      {
        name: 'User 11',
        image: Image11,
        link: '#0'
      },
      {
        name: 'User 05',
        image: Image05,
        link: '#0'
      },
      {
        name: 'User 12',
        image: Image12,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'One-Time'
  },
  {
    id: 6,
    category: '2',
    members: [
      {
        name: 'User 07',
        image: Image07,
        link: '#0'
      },
      {
        name: 'User 04',
        image: Image04,
        link: '#0'
      },
      {
        name: 'User 11',
        image: Image11,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'At Risk'
  },
  {
    id: 7,
    category: '4',
    members: [
      {
        name: 'User 01',
        image: Image01,
        link: '#0'
      },
      {
        name: 'User 02',
        image: Image02,
        link: '#0'
      },
      {
        name: 'User 06',
        image: Image06,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'One-Time'
  },
  {
    id: 8,
    category: '1',
    members: [
      {
        name: 'User 09',
        image: Image09,
        link: '#0'
      },
      {
        name: 'User 01',
        image: Image01,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'Off-Track'
  },
  {
    id: 9,
    category: '3',
    members: [
      {
        name: 'User 07',
        image: Image07,
        link: '#0'
      },
      {
        name: 'User 08',
        image: Image08,
        link: '#0'
      },
      {
        name: 'User 09',
        image: Image09,
        link: '#0'
      },
      {
        name: 'User 06',
        image: Image06,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'One-Time'
  },
  {
    id: 10,
    category: '4',
    members: [
      {
        name: 'User 06',
        image: Image06,
        link: '#0'
      },
      {
        name: 'User 11',
        image: Image11,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'Off-Track'
  },
  {
    id: 11,
    category: '2',
    members: [
      {
        name: 'User 05',
        image: Image05,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'Off-Track'
  },
  {
    id: 12,
    category: '3',
    members: [
      {
        name: 'User 07',
        image: Image07,
        link: '#0'
      },
      {
        name: 'User 08',
        image: Image08,
        link: '#0'
      },
      {
        name: 'User 09',
        image: Image09,
        link: '#0'
      },
    ],
    title: 'Monitor progress in Real Time Value',
    link: '#0',
    content: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts.',
    dates: {
      from: 'Jan 20',
      to: 'Jan 27'
    },
    type: 'At Risk'
  },
];

const cards = [
  {
    "key": "website_assessment",
    "type": "Limited-time free",
    "category": "2",
    "members": "",
    "link": "/tool/website-assessment",
    "dates": "",
  },
  {
    "key": "setup_company_profile",
    "type": "",
    "category": "settings",
    "members": "",
    "link": "/onboarding",
    "dates": "",
  },
  {
    "key": "ai_tools",
    "type": "",
    "category": "chip",
    "members": "",
    "link": "/content",
    "dates": "",
  },
  {
    "key": "content_generator_tools",
    "type": "",
    "category": "document",
    "members": "",
    "link": "/content",
    "dates": "",
  }
];


function DashboardCards(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  return (
    <div className="grid grid-cols-12 gap-6">
      {cards.map(card => (
        <CampaignsCard
          key={card.key}
          id={card.key}
          members={card.members}
          category={card.category}
          title={t(`cards.${card.key}.title`)}
          link={card.link}
          content={t(`cards.${card.key}.content`)}
          dates={card.dates}
          type={card.type}
        />
      ))}
    </div>

  );
}

export default DashboardCards;
