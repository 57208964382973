// ImageSection.js
import React from "react";
import OnboardingImage from "../../images/onboarding-image.jpg";

function ImageSection() {
  return (
    <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
      <img
        className="object-cover object-center w-full h-full"
        src={OnboardingImage}
        width="760"
        height="1024"
        alt="Onboarding"
      />
    </div>
  );
}

export default ImageSection;
