// src/i18n.js
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// Optionally, load translation files dynamically using HTTP backend
// import Backend from 'i18next-http-backend';
// Detect the user's language using the browser's settings
import LanguageDetector from 'i18next-browser-languagedetector';
//
// import translationEN from "./locales/en/translation.json";
// import translationFR from "./locales/fr/translation.json";
// import translationES from "./locales/es/translation.json";


i18n
  // .use(Backend) // Enable if you use backend loading for translations
  .use(LanguageDetector) // Automatically detect the language
  .use(initReactI18next) // Connect with React
  .init({
    resources: {
      en: {
        translation: {
          "welcome": "Welcome",
          "description": "This is a multi-language app",
          "Content_page_title": "Content Hub",
          "Content_page_description": "Define your brand's style, batch-generate content, and use AI tools for specific needs.",
          "Analytics_page_title": "Analytics",
          "Analytics_page_description": "The space where you can see all the data you need to make informed decisions.",
          "Dashboard_page_title": "Dashboard",
          "Tools_page_title": "Tools",
          "Dashboard_page_description": "Get a real-time snapshot of your marketing performance, AI insights, and upcoming content—all.",
          "Signup_title": "Get yourself an account",
          "buttonAction": "Sign up",
          "signinText": "Already have an account?",
          "signinAction": "Sign in",
          "Signin_title": "Welcome back",
          "signupAction": "Create an account",
          "forgotPassAction": "Forgot Password?",
          "forgotpass_title": "Get a new password",
          "forgotbuttonAction": "Reset password",
          "forgotsigninText": "Remember it after all?",
          "changepass_title": "Choose a new password",
          "changepass_buttonAction": "Change password",
          "Dashboard_welcome_message": "Hello! Great to see you here at Sunokrom. Our platform is in beta, and your experience is invaluable to us. Feel free to explore and share your thoughts. If you need help, our support chat is just a click away.",
          "cards": {
            "website_assessment": {
              "title": "Website Assessment",
              "content": "Discover how your website performs with our in-depth assessment. Get insights on speed, design, and functionality to improve your online presence."
            },
            "setup_company_profile": {
              "title": "Setup Your Company Profile",
              "content": "Create a compelling company profile that stands out! Our easy-to-use tools help you showcase your business and attract new customers."
            },
            "ai_tools": {
              "title": "AI Tools",
              "content": "Unlock the power of AI with our cutting-edge tools! Automate tasks, boost efficiency, and stay ahead of the competition with intelligent solutions."
            },
            "content_generator_tools": {
              "title": "Content Generator Tools",
              "content": "Create engaging content in minutes with our AI-powered tools! From blogs to social posts, we help you generate content that fits your brand."
            }
          },
          "tools": {
            "title": {
              "title": "Content Generator Tools",
              "description": ""
            },
            "brand_slogan_generator": {
              "title": "Brand Slogan Generator",
              "description": "Create memorable slogans tailored to your brand with AI-driven insights. Perfect for rebranding, marketing campaigns, and refreshing your brand identity."
            },
            "product_name_generator": {
              "title": "Product Name Generator",
              "description": "🚀 AI-powered naming genius: Transform product details into catchy, market-ready names instantly. Perfect for launches, rebrands, or creative blocks. Stand out effortlessly!"
            },
            "5_why_analysis": {
              "title": "5-Why Analysis",
              "description": "🔍 Dive deep with AI-powered 5-Why Analysis! Uncover root causes, brainstorm solutions. Perfect for problem-solvers and project managers. Customizable to fit your unique challenges. 🧠"
            },
            "contract_generator": {
              "title": "Contract Generator",
              "description": "Effortlessly create tailored contracts with this tool, ensuring accuracy and consistency. It simplifies the process but doesn’t replace the expertise of a lawyer."
            },
            "ai_army": {
              "title": "AI Army",
              "description": "Batalhão de IA: Transforme ideias em ação com inteligência artificial! Crie memes, posts, escolha nomes de bebês, receba dicas de cozinha e livros instantaneamente!"
            },
            "high_search_intent_blog_post_idea": {
              "title": "High Search Intent Blog Post Idea",
              "description": "🔮 AI blog whisperer: Feed it your URL, watch it decode audience desires. Instantly conjures 50+ tailored post ideas 📝 Transforming your website into a content goldmine 🚀"
            },
            "website_porters_5_forces_analysis": {
              "title": "Website Porter's 5 Forces Analysis",
              "description": "A user-friendly web tool that demystifies Porter's 5 Forces analysis. Input a URL to uncover key business insights, ideal for strategy novices and experts alike."
            },
            "website_feedback": {
              "title": "Website Feedback",
              "description": "Your website's feelings don't matter. Users do. We'll help you stop scaring them away."
            },
            "facebook_post": {
              "title": "Facebook Post",
              "description": "Make Aunt Karen proud! Craft posts that’ll have everyone clicking 'Like' faster than ever."
            },
            "twitter_post": {
              "title": "Twitter Post",
              "description": "Tweets so fire, even your ex will retweet. Stand out, 280 characters at a time."
            },
            "instagram_post": {
              "title": "Instagram Post",
              "description": "AI so good, it turns your Insta game from zero to influencer real quick."
            },
            "blog_post": {
              "title": "Blog Post",
              "description": "Type less, blog more. Let AI handle SEO so you can enjoy the applause."
            },
            "customer_birthday_message": {
              "title": "Customer Birthday Message",
              "description": "Never forget a customer’s birthday again! AI-crafted messages that even Grandma would approve."
            },
            "linkedin_post": {
              "title": "LinkedIn Post",
              "description": "LinkedIn content that screams, 'Hire me!' without you actually having to scream. Win-win!"
            }
          },
          "pricing": {
            "page": {
              "title": "Choose the best plan",
              "subtitle": "Get to know our premium solutions and get your startup off the ground in no time. Inspiring results from the first days.",
              "strapline": "Pricing",
            },
            "plans": {
              "starter": {
                "name": "Starter",
                "subtitle": "Basic users with minimal needs.",
                "perks": [
                  "Basic Content Creation",
                  "AI-Driven Post Optimization",
                  "Social Media Management for up 2 Platforms",
                  "Basic Analytics Dashboard",
                  "24/7 Email Support"
                ]
              },
              "pro": {
                "name": "Pro",
                "subtitle": "Growing businesses needing advanced automation.",
                "perks": [
                  "All feature from Start Plan plus",
                  "Advanced Content Creation & Scheduling",
                  "Multi-Platform Social Media Management (up to 5 platforms)",
                  "SEO Automation & Keyword Optimization",
                  "Advanced Analytics & Performance Tracking",
                  "Audience Segmentation Tools",
                  "24/7 Priority Support"
                ]
              },
              "business": {
                "name": "Business",
                "subtitle": "Established businesses with complex needs.",
                "perks": [
                  "All feature from Pro plan plus",
                  "Comprehensive Content Creation & Strategy Development",
                  "Full Social Media & SEO Coverage",
                  "Competitor Analysis & Trend Forecasting",
                  "AI-Powered Reporting & Optimization",
                  "Dedicated Account Manager",
                  "24/7 VIP Support"
                ]
              }
            }
          }
        },
      },
      pt: {
        translation: {
          "welcome": "Bem-vindo",
          "description": "Este é um aplicativo multilíngue",
          "Content_page_title": "Central de Conteúdo",
          "Content_page_description": "Defina o estilo da sua marca, gere conteúdo em lote e use ferramentas de IA para necessidades específicas.",
          "Analytics_page_title": "Análises",
          "Analytics_page_description": "O espaço onde você pode ver todos os dados necessários para tomar decisões informadas.",
          "Dashboard_page_title": "Painel",
          "Tools_page_title": "Ferramentas",
          "Dashboard_page_description": "Obtenha uma visão geral em tempo real do desempenho do seu marketing, insights de IA e conteúdo futuro—tudo em um só lugar.",
          "Signup_title": "Crie sua conta",
          "buttonAction": "Cadastrar",
          "signinText": "Já tem uma conta?",
          "signinAction": "Entrar",
          "Signin_title": "Bem-vindo",
          "signupAction": "Criar uma conta",
          "forgotPassAction": "Esqueceu sua senha?",
          "forgotpass_title": "Solicitar nova senha",
          "forgotbuttonAction": "Resetar senha",
          "forgotsigninText": "Lembrou da senha?",
          "changepass_title": "Digite sua nova senha",
          "changepass_buttonAction": "Trocar senha",
          "Dashboard_welcome_message": "Olá! Que bom ver você por aqui no Sunokrom. Nossa plataforma está em fase beta e sua experiência é muito importante para nós. Navegue à vontade e compartilhe suas impressões. Precisando de ajuda, use nosso chat de suporte.",
          "cards": {
            "website_assessment": {
              "title": "Análise de Website",
              "content": "Descubra como seu site está performando com nossa análise detalhada. Obtenha insights sobre velocidade, design e funcionalidade para melhorar sua presença online."
            },
            "setup_company_profile": {
              "title": "Configure o Perfil da Sua Empresa",
              "content": "Crie o perfil do seu negócio e customize. Nossas ferramentas fáceis de usar ajudam você a destacar seu negócio e atrair novos clientes."
            },
            "ai_tools": {
              "title": "Ferramentas de IA",
              "content": "Desbloqueie o poder da IA com nossas ferramentas de ponta! Automatize tarefas, aumente a eficiência e se mantenha à frente da concorrência com soluções inteligentes."
            },
            "content_generator_tools": {
              "title": "Ferramentas de Geração de Conteúdo",
              "content": "Crie conteúdos envolventes em minutos com nossas ferramentas de IA! Desde blogs até posts para redes sociais, ajudamos você a gerar conteúdos que se encaixam na sua marca."
            }
          },
          "tools": {
            "title": {
              "title": "Gerador de Conteúdo",
              "description": ""
            },
            "brand_slogan_generator": {
              "title": "Gerador de Slogans para Marcas",
              "description": "Crie slogans memoráveis que capturam a essência da sua marca. Com a ajuda da IA, você terá frases impactantes em instantes. Ótimo para campanhas publicitárias ou para dar uma repaginada na marca."
            },
            "product_name_generator": {
              "title": "Gerador de Nomes para Produtos",
              "description": "Transforme descrições de produtos em nomes prontos para o mercado com a inteligência da IA. Ideal para novos lançamentos, rebranding ou para aqueles momentos em que a criatividade te abandona."
            },
            "5_why_analysis": {
              "title": "Análise dos 5 Porquês",
              "description": "Explore as causas profundas de qualquer problema com esta ferramenta. A IA facilita a criação de soluções inteligentes. Perfeito para gestores e solucionadores de problemas que adoram ir direto ao ponto."
            },
            "contract_generator": {
              "title": "Gerador de Contratos",
              "description": "Crie contratos personalizados e precisos em poucos minutos. A ferramenta simplifica a formalização de acordos, mas lembre-se: é sempre bom dar uma conferida com um advogado antes de finalizar."
            },
            "ai_army": {
              "title": "Exército de IA",
              "description": "Um batalhão de ideias em um clique! A IA te ajuda a criar memes, posts, escolher nomes ou até planejar o jantar. Tudo isso com a agilidade de uma mente robótica!"
            },
            "high_search_intent_blog_post_idea": {
              "title": "Ideias de Posts para Blog com Alta Intenção de Busca",
              "description": "Deixe a IA decodificar o que seu público deseja ler. Digite seu site e obtenha mais de 50 ideias de posts personalizados, transformando seu blog em uma verdadeira mina de ouro de conteúdo."
            },
            "website_porters_5_forces_analysis": {
              "title": "Análise das 5 Forças de Porter para Websites",
              "description": "Torne a análise de Porter fácil! Insira uma URL e obtenha insights estratégicos valiosos para melhorar a competitividade do seu site. Ideal para quem quer pensar como um estrategista de negócios."
            },
            "website_feedback": {
              "title": "Feedback de Website",
              "description": "Esqueça os sentimentos do seu site! O que importa é o que seus usuários pensam. Esta ferramenta te ajuda a descobrir como não assustá-los e a manter uma experiência mais amigável."
            },
            "facebook_post": {
              "title": "Post para Facebook",
              "description": "Crie posts no Facebook que vão fazer sua Tia Karen correr para dar um 'Curtir'. Conteúdo atraente e envolvente para garantir que ninguém deixe passar sem interagir!"
            },
            "twitter_post": {
              "title": "Tweet",
              "description": "Quer fazer seu ex retweetar? Crie tweets irresistíveis em apenas 280 caracteres. Aproveite cada palavra e faça seus seguidores se apaixonarem novamente pelas suas postagens."
            },
            "instagram_post": {
              "title": "Post para Instagram",
              "description": "Transforme sua conta do Instagram com a ajuda da IA. De zero a influenciador em um piscar de olhos, com posts que garantem curtidas e novos seguidores."
            },
            "blog_post": {
              "title": "Post para Blog",
              "description": "Deixe a IA fazer o trabalho pesado com SEO enquanto você foca no que importa. Blogar nunca foi tão fácil: menos esforço, mais reconhecimento e, claro, mais aplausos."
            },
            "customer_birthday_message": {
              "title": "Mensagem de Aniversário para Clientes",
              "description": "Nunca mais esqueça o aniversário de um cliente! A IA cria mensagens personalizadas e emocionantes, do jeitinho que até a vovó aprovaria, com um toque de carinho e atenção."
            },
            "linkedin_post": {
              "title": "Post para LinkedIn",
              "description": "Posts no LinkedIn que falam por si: 'Me contrate!' sem precisar implorar. Seja profissional e envolvente ao mesmo tempo, garantindo que você se destaque na rede social de trabalho."
            }
          },
          "pricing": {
            "page": {
              "title": "Escolha o melhor plano",
              "subtitle": "Conheça nossas soluções premium e faça seu negócio decolar rapidamente. Resultados inspiradores desde os primeiros dias.",
              "strapline": "Preços",
            },
            "plans": {
              "starter": {
                "name": "Starter",
                "subtitle": "Usuários básicos com necessidades mínimas",
                "perks": [
                  "Criação de Conteúdo Básico",
                  "Otimização de Postagens com IA",
                  "Gestão de Redes Sociais para até 2 Plataformas",
                  "Painel de Análises Básico",
                  "Suporte por E-mail 24/7"
                ]
              },
              "pro": {
                "name": "Pro",
                "subtitle": "Negócios em crescimento que precisam de automação avançada.",
                "perks": [
                  "Todas as funcionalidades do plano Starter mais",
                  "Criação e Agendamento de Conteúdo Avançado",
                  "Gestão de Redes Sociais Multi-Plataforma (até 5 plataformas)",
                  "Automação de SEO e Otimização de Palavras-chave",
                  "Análises Avançadas e Monitoramento de Desempenho",
                  "Ferramentas de Segmentação de Público",
                  "Suporte Prioritário 24/7"
                ]
              },
              "business": {
                "name": "Business",
                "subtitle": "Empresas estabelecidas com necessidades complexas.",
                "perks": [
                  "Todas as funcionalidades do plano Pro mais",
                  "Criação de Conteúdo Abrangente e Desenvolvimento de Estratégia",
                  "Cobertura Completa de Redes Sociais e SEO",
                  "Análise de Concorrentes e Previsão de Tendências",
                  "Relatórios e Otimização com IA",
                  "Gerente de Conta Dedicado",
                  "Suporte VIP 24/7"
                ]
              }
            }

          }

        },

      },
      es: {
        "translation": {
          "welcome": "Bienvenido",
          "description": "Esta es una aplicación multilingüe",
          "Content_page_title": "Centro de Contenidos",
          "Content_page_description": "Define el estilo de tu marca, genera contenido en lotes y usa herramientas de IA para necesidades específicas.",
          "Analytics_page_title": "Analítica",
          "Analytics_page_description": "El espacio donde puedes ver todos los datos que necesitas para tomar decisiones informadas.",
          "Dashboard_page_title": "Panel de Control",
          "Tools_page_title": "Herramientas",
          "Dashboard_page_description": "Obtén una vista en tiempo real de tu rendimiento de marketing, ideas de IA y contenido próximo—todo en un solo lugar.",
          "Signup_title": "Crea tu cuenta",
          "buttonAction": "Regístrate",
          "signinText": "¿Ya tienes una cuenta?",
          "signinAction": "Iniciar sesión",
          "Signin_title": "Bienvenido de nuevo",
          "signupAction": "Crear una cuenta",
          "forgotPassAction": "¿Olvidaste tu contraseña?",
          "forgotpass_title": "Obtén una nueva contraseña",
          "forgotbuttonAction": "Restablecer contraseña",
          "forgotsigninText": "¿La recordaste después de todo?",
          "changepass_title": "Elige una nueva contraseña",
          "changepass_buttonAction": "Cambiar contraseña",
          "Dashboard_welcome_message": "¡Hola! Nos alegra verte en Sunokrom. Nuestra plataforma está en fase beta y tu experiencia es valiosa para nosotros. Explora y comparte tus ideas. Si necesitas ayuda, nuestro chat de soporte está a un clic de distancia.",
          "cards": {
            "website_assessment": {
              "title": "Evaluación del Sitio Web",
              "content": "Descubre cómo funciona tu sitio web con nuestra evaluación detallada. Obtén información sobre velocidad, diseño y funcionalidad para mejorar tu presencia en línea."
            },
            "setup_company_profile": {
              "title": "Configura el Perfil de tu Empresa",
              "content": "Crea un perfil empresarial atractivo que destaque. Nuestras herramientas fáciles de usar te ayudarán a mostrar tu negocio y atraer nuevos clientes."
            },
            "ai_tools": {
              "title": "Herramientas de IA",
              "content": "Desbloquea el poder de la IA con nuestras herramientas avanzadas. Automatiza tareas, mejora la eficiencia y mantente por delante de la competencia con soluciones inteligentes."
            },
            "content_generator_tools": {
              "title": "Generador de Contenidos",
              "content": "Crea contenido atractivo en minutos con nuestras herramientas impulsadas por IA. Desde blogs hasta publicaciones en redes sociales, te ayudamos a generar contenido que se ajuste a tu marca."
            }
          },
          "tools": {
            "title": {
              "title": "Generador de Contenidos",
              "description": ""
            },
            "brand_slogan_generator": {
              "title": "Generador de Esloganes",
              "description": "Crea eslóganes memorables personalizados para tu marca con la ayuda de la IA. Ideal para rebranding, campañas de marketing y para refrescar la identidad de tu marca."
            },
            "product_name_generator": {
              "title": "Generador de Nombres de Productos",
              "description": "🚀 Genio de los nombres con IA: Transforma los detalles del producto en nombres atractivos y listos para el mercado al instante. Perfecto para lanzamientos, rebrands o cuando te falte creatividad."
            },
            "5_why_analysis": {
              "title": "Análisis de los 5 Porqués",
              "description": "🔍 Profundiza con el análisis de los 5 Porqués impulsado por IA. Descubre las causas raíz y crea soluciones. Perfecto para solucionadores de problemas y gerentes de proyectos. Personalizable a tus desafíos únicos. 🧠"
            },
            "contract_generator": {
              "title": "Generador de Contratos",
              "description": "Crea contratos personalizados con facilidad, garantizando precisión y consistencia. Esta herramienta simplifica el proceso, pero no sustituye la experiencia de un abogado."
            },
            "ai_army": {
              "title": "Ejército de IA",
              "description": "¡Ejército de IA al rescate! Convierte ideas en acción con inteligencia artificial. Crea memes, publicaciones, elige nombres para bebés y obtén consejos de cocina y libros al instante."
            },
            "high_search_intent_blog_post_idea": {
              "title": "Ideas para Posts con Alta Intención de Búsqueda",
              "description": "🔮 Maestro de blogs con IA: Introduce tu URL y mira cómo descifra los deseos de tu audiencia. Genera instantáneamente más de 50 ideas personalizadas de posts 📝 Transformando tu sitio en una mina de oro de contenido 🚀"
            },
            "website_porters_5_forces_analysis": {
              "title": "Análisis de las 5 Fuerzas de Porter",
              "description": "Una herramienta web fácil de usar que desmitifica el análisis de las 5 Fuerzas de Porter. Introduce una URL para descubrir información clave de negocios, ideal tanto para principiantes como para expertos en estrategia."
            },
            "website_feedback": {
              "title": "Retroalimentación del Sitio Web",
              "description": "Los sentimientos de tu sitio web no importan. Los usuarios sí. Te ayudamos a dejar de espantarlos."
            },
            "facebook_post": {
              "title": "Post para Facebook",
              "description": "¡Haz que la Tía Karen esté orgullosa! Crea publicaciones que harán que todos hagan clic en 'Me gusta' más rápido que nunca."
            },
            "twitter_post": {
              "title": "Tweet",
              "description": "Tweets tan buenos que hasta tu ex los retuiteará. Destácate, 280 caracteres a la vez."
            },
            "instagram_post": {
              "title": "Post para Instagram",
              "description": "IA tan buena que transforma tu Instagram de cero a influenciador en un abrir y cerrar de ojos."
            },
            "blog_post": {
              "title": "Post para Blog",
              "description": "Escribe menos, bloguea más. Deja que la IA se encargue del SEO mientras tú disfrutas de los aplausos."
            },
            "customer_birthday_message": {
              "title": "Mensaje de Cumpleaños para Clientes",
              "description": "Nunca más olvides el cumpleaños de un cliente. Mensajes personalizados creados por IA que incluso a la abuela le encantarán."
            },
            "linkedin_post": {
              "title": "Post para LinkedIn",
              "description": "Contenido para LinkedIn que grita '¡Contrátame!' sin que tengas que levantar la voz. ¡Éxito asegurado!"
            }
          },
          "pricing": {
            "page": {
              "title": "Elige el mejor plan",
              "subtitle": "Conoce nuestras soluciones premium y haz despegar tu negócio en poco tiempo. Resultados inspiradores desde los primeros días.",
              "strapline": "Precios"
            },
            "plans": {
              "starter": {
                "name": "Starter",
                "subtitle": "Usuarios básicos con necesidades mínimas.",
                "perks": [
                  "Creación de Contenido Básico",
                  "Optimización de Publicaciones con IA",
                  "Gestión de Redes Sociales para hasta 2 Plataformas",
                  "Panel de Análisis Básico",
                  "Soporte por Correo 24/7"
                ]
              },
              "pro": {
                "name": "Pro",
                "subtitle": "Negocios en crecimiento que necesitan automatización avanzada.",
                "perks": [
                  "Todas las funcionalidades del plan Starter más",
                  "Creación y Programación Avanzada de Contenidos",
                  "Gestión de Redes Sociales en varias plataformas (hasta 5 plataformas)",
                  "Automatización SEO y Optimización de Palabras Clave",
                  "Análisis Avanzado y Seguimiento de Desempeño",
                  "Herramientas de Segmentación de Audiencia",
                  "Soporte Prioritario 24/7"
                ]
              },
              "business": {
                "name": "Business",
                "subtitle": "Empresas consolidadas con necesidades complejas.",
                "perks": [
                  "Todas las funcionalidades del plan Pro más",
                  "Creación de Contenidos Completa y Desarrollo de Estrategias",
                  "Cobertura Total de Redes Sociales y SEO",
                  "Análisis de Competencia y Predicción de Tendencias",
                  "Informes y Optimización con IA",
                  "Gerente de Cuenta Dedicado",
                  "Soporte VIP 24/7"
                ]
              }
            }
          }
        }
      }
      // Add more languages here
    },
    fallbackLng: 'en', // Fallback language if the detected language is not available
    debug: true, // Set to false in production
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
