import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useFormContext} from '../../util/FormContext';

const Step3Form = forwardRef((props, ref) => {
  const {formData, updateFormData} = useFormContext();

  // State for form fields, initialized from formData
  const [formFields, setFormFields] = useState({
    companyName: formData.companyName || '',
    city: formData.city || '',
    postalCode: formData.postalCode || '',
    street: formData.street || '',
    country: formData.country || 'USA',
  });

  // State for form errors
  const [errors, setErrors] = useState({});

  // Synchronize formData with state changes
  useEffect(() => {
    updateFormData(formFields);
    window.scrollTo(0, 0);
  }, []);

  // Generic handle change function for all inputs
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));

    updateFormData(formFields);
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    Object.keys(formFields).forEach((field) => {
      if (!formFields[field]) {
        newErrors[field] = true;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  // Expose validation and submission functions to parent component
  useImperativeHandle(ref, () => ({
    validateForm,
    submitForm: () => {
      if (validateForm()) {
        updateFormData(formFields);
      }
    },
  }));

  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-gray-800 dark:text-gray-100 font-bold mb-6">Additional information</h1>
        <form>
          <div className="space-y-4">
            {/* Street Address */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="street">
                Street Address <span className="text-red-500">*</span>
              </label>
              <input
                id="street"
                name="street"
                className={`form-input w-full text-black ${errors.street ? 'border-red-500' : ''}`}
                type="text"
                value={formFields.street}
                onChange={handleChange}
              />
              {errors.street && <p className="text-red-500">Please enter the street address.</p>}
            </div>
            {/* City and Postal Code */}
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="city">
                  City <span className="text-red-500">*</span>
                </label>
                <input
                  id="city"
                  name="city"
                  className={`form-input w-full text-black ${errors.city ? 'border-red-500' : ''}`}
                  type="text"
                  value={formFields.city}
                  onChange={handleChange}
                />
                {errors.city && <p className="text-red-500">Please enter the city.</p>}
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="postalCode">
                  Postal Code <span className="text-red-500">*</span>
                </label>
                <input
                  id="postalCode"
                  name="postalCode"
                  className={`form-input w-full text-black ${errors.postalCode ? 'border-red-500' : ''}`}
                  type="text"
                  value={formFields.postalCode}
                  onChange={handleChange}
                />
                {errors.postalCode && <p className="text-red-500">Please enter the postal code.</p>}
              </div>
            </div>


            {/* Country */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="country">
                Country <span className="text-red-500">*</span>
              </label>
              <select
                id="country"
                name="country"
                className={`form-select w-full text-black ${errors.country ? 'border-red-500' : ''}`}
                value={formFields.country}
                onChange={handleChange}
              >
                <option value="United States">United States</option>
                <option value="Italy">Brasil</option>
                <option value="United Kingdom">United Kingdom</option>
              </select>
              {errors.country && <p className="text-red-500">Please select a country.</p>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});

export default Step3Form;
