export const TOOLS_INFO = [
  /* TEMPLATE. COPY THIS AND FILL IN YOUR TOOL INFO
    {
      slug: "",

      icon: "",

      title: "",

      description: "",

      category: "",

      workflowID: "",

      copywriting:
        ``,
    },
  */

    // website-assessment

    {
        slug: "website-assessment", // the slug that, when clicked on, will open your tool on, for example: https://your_website/podcast-guest-researcher
    icon: "🗒", // icon of the tool for visuals
        title: "Website Assessment", // title of the tool
        description: "Uncover your website’s full potential with a comprehensive assessment. Optimize  functionality, and design to boost performance and elevate user experience seamlessly.", // description of the tool
    category: "", // optional, can categorize your tools
        workflowID: "website-assessment", // the ID of the workflow on MindPal, can be viewed when sharing the link, it is the ID after `https://workflow.mindpal.space/669b2c0364d31ec6f5ec0361`
        copywriting: "Unleash your website's true potential with a detailed, in-depth assessment. Analyze speed, functionality, SEO performance, and design to enhance user experience and drive better results. Our expert recommendations ensure your website stays competitive and fully optimized for success.", // A more detailed introduction to your tools, so you can share it with others
  },
  {
      slug: "branding-audit", // the slug that, when clicked on, will open your tool on, for example: https://your_website/podcast-guest-researcher
      icon: "🕵️", // icon of the tool for visuals
      title: "Branding audit", // title of the tool
      description: "The Branding Audit AI reviews key brand elements like user personas, UVP, logo, colors, and tagline. It offers insights and recommendations to improve brand consistency and effectiveness.", // description of the tool
    category: "", // optional, can categorize your tools
      workflowID: "branding-audit", // the ID of the workflow on MindPal, can be viewed when sharing the link, it is the ID after `https://workflow.mindpal.space/669b2c0364d31ec6f5ec0361`
      copywriting: "The Branding Audit AI workflow conducts a thorough evaluation of your brand's foundational elements, including user personas, unique value proposition (UVP), logo, color scheme, and tagline. By analyzing these critical components, it offers actionable insights and tailored recommendations to strengthen your brand’s identity. This audit ensures consistency across all platforms and helps refine your messaging for maximum impact. With data-driven suggestions, you can enhance your brand’s effectiveness, resonate more deeply with your target audience, and ultimately differentiate your business from competitors.", // A more detailed introduction to your tools, so you can share it with others
  },
    //
    // {
    //   slug: "instagram", // the slug that, when clicked on, will open your tool on, for example: https://your_website/podcast-guest-researcher
    //
    //   icon: "🗒", // icon of the tool for visuals
    //
    //   title: "Instagram Post", // title of the tool
    //
    //   description: "Generates concise summaries of YouTube videos to quickly convey their main content.", // description of the tool
    //
    //   category: "", // optional, can categorize your tools
    //
    //   workflowID: "resumao-do-clei", // the ID of the workflow on MindPal, can be viewed when sharing the link, it is the ID after `https://workflow.mindpal.space/669b2c0364d31ec6f5ec0361`
    //
    //   copywriting: "YouTube Summary is likely an online tool that analyzes YouTube videos and generates concise summaries, extracting key points and main ideas to help users quickly grasp video content without watching the entire video.", // A more detailed introduction to your tools, so you can share it with others
    // },
    // // EXAMPLE & GUIDANCE
    // {
    //   slug: "youtube-summary", // the slug that, when clicked on, will open your tool on, for example: https://your_website/podcast-guest-researcher
    //
    //   icon: "🗒", // icon of the tool for visuals
    //
    //   title: "Youbube summary", // title of the tool
    //
    //   description: "Generates concise summaries of YouTube videos to quickly convey their main content.", // description of the tool
    //
    //   category: "", // optional, can categorize your tools
    //
    //   workflowID: "resumao-do-clei", // the ID of the workflow on MindPal, can be viewed when sharing the link, it is the ID after `https://workflow.mindpal.space/669b2c0364d31ec6f5ec0361`
    //
    //   copywriting: "YouTube Summary is likely an online tool that analyzes YouTube videos and generates concise summaries, extracting key points and main ideas to help users quickly grasp video content without watching the entire video.", // A more detailed introduction to your tools, so you can share it with others
    // },
    //
    // // PRESETS
    // {
    //   slug: "podcast-seo-optimizer",
    //
    //   icon: "🎙️",
    //
    //   title: "Podcast SEO-friendly Info Generator",
    //
    //   description: "Generate SEO-friendly Titles, Descriptions, and Tags for Podcast Episodes",
    //
    //   category: "",
    //
    //   workflowID: "podcast-seo-optimizer",
    //
    //   copywriting:
    //     "The Podcast SEO-friendly Info Generator is a powerful tool designed specifically for podcast creators aiming to enhance their visibility and engagement. This innovative solution streamlines the content creation process, making it easier for podcasters to connect with their audience and improve their search engine optimization (SEO).",
    // },
    //
    // {
    //   slug: "podcast-recap-generator",
    //
    //   icon: "📝",
    //
    //   title: "Podcast Recap Generator",
    //
    //   description: "Generate engaging newsletter content and social media posts from their podcast episodes",
    //
    //   category: "",
    //
    //   workflowID: "podcast-recap-generator",
    //
    //   copywriting:
    //     "The Podcast Recap Generator is an innovative solution designed specifically for podcast creators who want to enhance their content distribution and audience engagement. This powerful tool streamlines the process of transforming podcast episodes into captivating newsletter content, making it simple for users to share valuable insights with their followers.",
    // },
]
