import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useFormContext} from '../../util/FormContext';

const Step2Form = forwardRef((props, ref) => {
  const {formData, updateFormData} = useFormContext();
  const [loading, setLoading] = useState(true); // Track loading state

  // State for form fields, initialized from formData
  const [formFields, setFormFields] = useState({
    companyName: formData.companyName || '',
    city: formData.city || '',
    postalCode: formData.postalCode || '',
    street: formData.street || '',
    country: formData.country || 'USA',
    facebook: formData.facebook || '',
    instagram: formData.instagram || '',
    twitter: formData.twitter || '',
    linkedin: formData.linkedin || '',
    googleBusiness: formData.googleBusiness || false,
    websiteTechnology: formData.websiteTechnology || 'Other',
  });


  useEffect(() => {
    updateFormData(formFields);
    window.scrollTo(0, 0);
    setLoading(false);
  }, []);

  // State for form errors
  const [errors, setErrors] = useState({});

  // Generic handle change function for all inputs
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));

    updateFormData(formFields);
  };

  const handleChangeCheckBox = (e) => {
    const {name, checked} = e.target;

    console.log("Before update:", formFields); // Log state before update

    setFormFields((prevFields) => {
      const newState = {
        ...prevFields,
        [name]: checked,
      };
      console.log("New state:", newState); // Log the new state after update
      return newState;
    });

    updateFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };


  // Validation function
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    Object.keys(formFields).forEach((field) => {
      if (!formFields[field] && field !== 'facebook' && field !== 'instagram' && field !== 'twitter' && field !== 'linkedin' && field !== 'googleBusiness') {
        newErrors[field] = true;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  // Expose validation and submission functions to parent component
  useImperativeHandle(ref, () => ({
    validateForm,
    submitForm: () => {
      if (validateForm()) {
        updateFormData(formFields);
      }
    },
  }));

  if (loading) {
    return <div>Loading...</div>; // Replace with a loading spinner or similar
  }

  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-gray-800 dark:text-gray-100 font-bold mb-6">Social Networks</h1>
        <form>
          <div className="space-y-4 mb-8">
            {formData.option === 'website' && (
              <div>
                <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="company-website">
                  Website
                </label>
                <input
                  id="company-website"
                  className="form-input w-full"
                  type="text"
                  placeholder={formData.website}
                  disabled={true}
                />
              </div>
            )}

            {/* Company Name */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="companyName">
                Company Name <span className="text-red-500">*</span>
              </label>
              <input
                id="companyName"
                name="companyName"
                className={`form-input w-full text-black ${errors.companyName ? 'border-red-500' : ''}`}
                type="text"
                value={formFields.companyName}
                onChange={handleChange}
                autoComplete="companyName"
              />
              {errors.companyName && <p className="text-red-500">Please enter the company name.</p>}
            </div>

            {/* Social Media Fields */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="facebook">
                Facebook
              </label>
              <input
                id="facebook"
                name="facebook"
                className="form-input w-full  text-black"
                type="text"
                value={formFields.facebook}
                onChange={handleChange}
                placeholder="https://facebook.com/yourpage"
                autoComplete="facebook"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="instagram">
                Instagram
              </label>
              <input
                id="instagram"
                name="instagram"
                className="form-input w-full text-black"
                type="text"
                value={formFields.instagram}
                onChange={handleChange}
                placeholder="https://instagram.com/yourprofile"
                autoComplete="instagram"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="twitter">
                Twitter
              </label>
              <input
                id="twitter"
                name="twitter"
                className="form-input w-full text-black"
                type="text"
                value={formFields.twitter}
                onChange={handleChange}
                placeholder="https://twitter.com/yourhandle"
                autoComplete="twitter"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="linkedin">
                LinkedIn
              </label>
              <input
                id="linkedin"
                name="linkedin"
                className="form-input w-full text-black"
                type="text"
                value={formFields.linkedin}
                onChange={handleChange}
                placeholder="https://linkedin.com/in/yourprofile"
                autoComplete="linkedin"
              />
            </div>
            {/* Website Technology */}
            <div>
              <label className="block text-sm font-medium mb-1 text-violet-300" htmlFor="websiteTechnology">
                Website Technology <span className="text-red-500">*</span>
              </label>
              <select
                id="websiteTechnology"
                name="websiteTechnology"
                className={`form-select w-full text-black ${errors.websiteTechnology ? 'border-red-500' : ''}`}
                value={formFields.websiteTechnology}
                onChange={handleChange}
              >
                <option value="Other">Other</option>
                <option value="Wordpress">WordPress</option>
                <option value="Wix">Wix</option>
                <option value="Shopify">Shopify</option>
              </select>
              {errors.websiteTechnology && <p className="text-red-500">Please select a website technology.</p>}
            </div>

            {/*  GOOGLE BUSINESS */}
            <div className="flex items-center ">
              <input
                id="googleBusiness"
                name="googleBusiness"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                checked={formFields.googleBusiness} // Bind to state
                onChange={handleChangeCheckBox} // Handle state change
              />
              <label htmlFor="googleBusiness"
                     className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
                I have Google Business listing
              </label>
            </div>

          </div>
        </form>
      </div>
    </div>
  );
});

export default Step2Form;
