// Header.js
import React from "react";

function Header() {
  return (
    <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center pt-5">
        <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">
          Few Steps to Skyrocket Your Brand
        </h1>
      </div>
    </div>
  );
}

export default Header;
