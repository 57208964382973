// checkWebsiteAvailability.js
import axios from 'axios';

export async function checkWebsiteAvailability(url) {
  const axiosProxy = 'https://cors-anywhere.herokuapp.com/';
  try {
    console.log(`Checking website ${url}`);
    const response = await axios.get(`${axiosProxy}/${url}`, {
      timeout: 7000,
    });
    return response.status === 200;
  } catch (error) {
    return false;
  }
}
