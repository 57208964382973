import React from "react";
import "./../styles/global.css";
import './../util/i18n'; // Import the i18n config
import ThemeProvider from "./../utils/ThemeContext";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import {Route, Router, Switch} from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import Chat from "./../components/Chat";
import {AuthProvider} from "./../util/auth";
import {QueryClientProvider} from "./../util/db";
import OnboardingPage from "./onboarding";

import {FormProvider} from "../util/FormContext";
import analyticsPage from "./analyticsPage";

import "./../charts/ChartjsConfig";
import ContentPage from "./content";
import ToolsPage from "./tools";
import PurchasePage from "./purchase";

function App(props) {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <FormProvider>
            <Chat/>
            <Router>
              <>
                <div className="font-inter antialiased bg-gray-100 dark:bg-gray-900 text-gray-600 dark:text-gray-400">
                  {/*<Sidebar/>*/}
                  <Navbar/>

                  <Switch>
                    <Route exact path="/" component={IndexPage}/>

                    <Route exact path="/about" component={AboutPage}/>

                    <Route exact path="/faq" component={FaqPage}/>

                    <Route exact path="/contact" component={ContactPage}/>

                    <Route exact path="/pricing" component={PricingPage}/>

                    <Route exact path="/dashboard" component={DashboardPage}/>

                    <Route exact path="/content" component={ContentPage}/>

                    <Route exact path="/onboarding" component={OnboardingPage}/>

                    <Route exact path="/analytics" component={analyticsPage}/>

                    <Route exact path="/auth/:type" component={AuthPage}/>

                    <Route exact path="/tool/:slug" component={ToolsPage}/>

                    <Route exact path="/settings/:section" component={SettingsPage}/>

                    <Route exact path="/legal/:section" component={LegalPage}/>

                    <Route exact path="/purchase/:plan" component={PurchasePage}/>

                    <Route component={NotFoundPage}/>
                  </Switch>

                  <Footer
                    size="xm"
                    bgColor="bg-gray-100 dark:bg-gray-800"
                    bgImage=""
                    bgImageOpacity={1}
                    textColor=""
                    sticky={true}
                  />
                </div>
              </>
            </Router>
          </FormProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
