import React from "react";
import Meta from "../components/Meta";
import {requireAuth} from "../util/auth";
import Onboarding01 from "../components/Onboarding/Onboarding01";

function OnboardingPage(props) {
  return (
    <>
      {/* botton padding 5 px */}
      <Meta title="Onboarding"/>
      <div className="pb-20">
        <Onboarding01/>
      </div>
    </>
  );
}

export default requireAuth(OnboardingPage);
