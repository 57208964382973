import React, {useEffect, useRef, useState} from 'react';
import {Transition} from '@headlessui/react';
import {useFormContext} from '../../util/FormContext';
import {checkWebsiteAvailability} from '../../util/checkWebsiteAvailability';
import Spinner from '../Spinner';

function Step1Form() {
  const {formData, updateFormData} = useFormContext();
  const [selectedOption, setSelectedOption] = useState(formData.option || 'website');
  const [website, setWebsite] = useState(formData.website || '');
  const [isValidUrl, setIsValidUrl] = useState(null);
  const [isWebsiteAvailable, setIsWebsiteAvailable] = useState(null);
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null); // useRef to store the timeout ID

  const [formFields, setFormFields] = useState({
    option: formData.option || 'website',
    website: formData.website || '',
  });

  useEffect(() => {
    updateFormData(formFields);
    window.scrollTo(0, 0);
  }, []);


  const handleOptionChange = (event) => {
    const {value} = event.target;
    // Update the selected option state
    setSelectedOption(value);
    // Update the formData context with the new option value
    updateFormData({option: value});
  };

  const handleWebsiteChange = (event) => {
    const {value} = event.target;
    if (value === '') {
      setWebsite('');
      return;
    }
    setWebsite(value.toLowerCase());
    updateFormData({website: value});

    // Clear the previous timeout if user types again
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to delay the check
    debounceTimeout.current = setTimeout(() => {
      const isValid = validateUrl(value);
      setIsValidUrl(isValid);

      if (isValid) {
        handleCheckWebsite(value);
      } else {
        setIsWebsiteAvailable(null);
      }
    }, 500); // Delay of 500ms
  };

  const validateUrl = (url) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // Protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // Domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // Query string
      '(\\#[-a-z\\d_]*)?$', 'i' // Fragment locator
    );
    return !!pattern.test(url);
  };

  const handleCheckWebsite = async () => {
    setLoading(true);
    const formattedUrl = website.startsWith('http') ? website : `https://${website}`;

    if (validateUrl(formattedUrl)) {
      setIsValidUrl(true);
      const available = await checkWebsiteAvailability(formattedUrl);
      setIsWebsiteAvailable(available);
    } else {
      setIsValidUrl(false);
      setIsWebsiteAvailable(null);
    }
    setLoading(false);
  };

  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <h1 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-6">
          Tell us which option better fits you
        </h1>
        <form>
          <div className="sm:flex space-y-3 sm:space-y-0 sm:space-x-4 mb-8">

            {/* OPTION 1 - WEBSITE */}
            <label className="flex-1 relative block cursor-pointer">
              <input
                type="radio"
                name="radio-buttons"
                value="website"
                className="peer sr-only"
                checked={selectedOption === 'website'}
                onChange={handleOptionChange}
              />
              <div
                className="h-full text-center bg-white dark:bg-gray-800 px-4 py-6 rounded-lg border border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 shadow-sm transition">
                <svg
                  className="inline-flex fill-current text-violet-500 mt-2 mb-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13 22V11a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v13H0V14a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v8h2Zm6-15h-2V3a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7H5V3a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v4ZM9 22v-8a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v8h7Zm13 0V11a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v11h7Zm-5-8v-2h3v2h-3Zm0 3v-2h3v2h-3Zm0 3v-2h3v2h-3ZM4 20v-2h3v2H4Zm0-3v-2h3v2H4Z"
                  />
                </svg>
                <div className="font-semibold text-gray-800 dark:text-gray-100 mb-1">Organization</div>
                <div className="text-sm text-violet-300">Select this option if you have a website.</div>
              </div>
              <div
                className="absolute inset-0 border-2 border-transparent peer-checked:border-violet-400 dark:peer-checked:border-violet-500 rounded-lg pointer-events-none"
                aria-hidden="true"
              ></div>
            </label>

            {/* OPTION 2 - JUST SOCIAL */}
            <label className="flex-1 relative block cursor-pointer">
              <input
                type="radio"
                name="radio-buttons"
                value="social"
                className="peer sr-only"
                checked={selectedOption === 'social'}
                onChange={handleOptionChange}
              />
              <div
                className="h-full text-center bg-white dark:bg-gray-800 px-4 py-6 rounded-lg border border-gray-200 dark:border-gray-700/60 hover:border-gray-300 dark:hover:border-gray-600 shadow-sm transition">
                <svg
                  className="inline-flex fill-current text-violet-500 mt-2 mb-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10a9.955 9.955 0 0 1-2.003 6.005 2 2 0 0 0-1.382-1.115l-3.293-.732-.295-1.178A4.992 4.992 0 0 0 17 11v-1a5 5 0 0 0-10 0v1c0 1.626.776 3.07 1.977 3.983l-.294 1.175-3.293.732a1.999 1.999 0 0 0-1.384 1.119A9.956 9.956 0 0 1 2 12Zm3.61 7.693A9.96 9.96 0 0 0 12 22c2.431 0 4.66-.868 6.393-2.31l-.212-.847-4.5-1-.496-1.984a5.016 5.016 0 0 1-2.365 0l-.496 1.983-4.5 1-.213.85ZM12 7a3 3 0 0 0-3 3v1a3 3 0 1 0 6 0v-1a3 3 0 0 0-3-3Z"
                    fillRule="evenodd"
                  />
                </svg>
                <div className="font-semibold text-gray-800 dark:text-gray-100 mb-1">Individual</div>
                <div className="text-sm text-violet-300">Select this option if you just have social network (facebook,
                  twitter, etc).
                </div>
              </div>
              <div
                className="absolute inset-0 border-2 border-transparent peer-checked:border-violet-400 dark:peer-checked:border-violet-500 rounded-lg pointer-events-none"
                aria-hidden="true"
              ></div>
            </label>
          </div>

          {/* Website Field - Placed Below the Options */}
          {selectedOption === 'website' && (
            <div className="mt-3">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Website URL
              </label>
              <input
                type="text"
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-100"
                placeholder="https://your-website-here.com"
                value={website}
                onChange={handleWebsiteChange}
                autoComplete="website"
              />
              {/* Loading Spinner */}
              <Transition
                show={loading}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Spinner/>
              </Transition>

              {/* Validation and Availability Messages */}
              {!loading && website === '' && (
                <p className="text-gray-500">Please enter your website URL</p>)}
              {!loading && website !== '' && isValidUrl === false && (
                <p className="text-red-500">🚫 Invalid URL format</p>
              )}
              {!loading && isValidUrl === true && isWebsiteAvailable === false && (
                <p className="text-red-500">❌ Website is not available</p>
              )}
              {!loading && isValidUrl === true && isWebsiteAvailable === true && (
                <p className="text-green-500">✅ Website is online</p>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Step1Form;
